
import Vue from "vue";
import Component from "vue-class-component";
import UserActivityPanel from "@/components/UserActivityPanel.vue";
import CurrentSummaryPanel from "@/components/CurrentSummaryPanel.vue";
import PageTabs from "@/components/PageTabs.vue";
import ReviewLockModal, { IInputData } from "@/components/ReviewLockModal.vue";
import { Prop, Watch } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import {
  IAccountSummary,
  IValidatorAndVotesItem,
  IValidatorListItem,
  IVotesForValidators
} from "@maplabs/map-atlas-sdk";
import { BigNumber } from "bignumber.js";
import { find } from "lodash";
import {
  addressIsEqual,
  normalValueToWeiBignumber,
  weiBigNumberToNormalValue,
  weiBigNumberToNormalValueFixed,
} from "@/utils/tools";
import { AppModule } from "@/store/modules/app";

interface IValidator {
  apy: number,
  validator: string,
  commission: number,
  name: string,
  proportion: number,
  totalVoteNum: number,
  status: number,
}

@Component({
  components: {
    ReviewLockModal,
    PageTabs,
    CurrentSummaryPanel,
    UserActivityPanel,
  },
  apollo: {
    allValidators: {
      query: require("../../apollo/graphql/Validators.graphql"),
    },
  },
})
export default class VotePage extends Vue {
  @Prop({
    default: "",
  })
  public address!: string;

  public tabIndex = 0;

  public reviewLock = false;

  private summaryType = 1;

  private inputValue = "";

  private loading = true;

  private userSummary: IAccountSummary = {
    userBalance: {
      totalLocked: new BigNumber(0),
      balance: new BigNumber(0),
      nonVoting: new BigNumber(0),
      requirement: new BigNumber(0),
      pendingWithdraw: new BigNumber(0),
    },
    pendingWithdrawals: [],
  };

  private votedList: IValidatorAndVotesItem[] = [];

  private summaryTotal = 0;

  private summaryList: { [key: string]: string | number }[] = [];

  public selectedValidator = "";

  private validators: IValidatorListItem[] = [];

  private votesForValidator: IVotesForValidators = {
    validator: "",
    pending: new BigNumber(0),
    active: new BigNumber(0),
    all: new BigNumber(0),
  };

  public modalData: IInputData = {
    type: "",
    value: "0",
    fee: "0",
    extraData: { name: "" },
  };

  private get continueEnable() {
    return parseFloat(this.inputValue) > 0;
  }

  private inputChange() {
    let compareValue = new BigNumber(0);
    switch (this.tabIndex) {
      case 0:
        compareValue = this.userSummary.userBalance.nonVoting;
        break;
      case 1:
        compareValue = this.votesForValidator.all;
        break;
      default:
        break;
    }
    if ((new BigNumber(this.inputValue)).isGreaterThan(weiBigNumberToNormalValue(compareValue))) {
      this.inputValue = weiBigNumberToNormalValue(compareValue);
    }
  }

  private get votedListResult() {
    const arr: { [key: string]: string | number }[] = [];
    let inputValue = parseFloat(this.inputValue);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(inputValue)) {
      inputValue = 0;
    }
    let match = false;
    this.votedList.forEach((item) => {
      // let activeValue = parseFloat(weiBigNumberToNormalValue(item.active));
      // let pendingValue = parseFloat(weiBigNumberToNormalValue(item.pending));
      let activeValue = item.active;
      let pendingValue = item.pending;
      const inputWei = normalValueToWeiBignumber(inputValue);
      if (addressIsEqual(item.address, this.address)) {
        match = true;
        if (this.tabIndex === 0) {
          pendingValue  =  pendingValue.plus(inputWei);
        } else if (pendingValue.isGreaterThanOrEqualTo(inputWei)) {
          pendingValue = pendingValue.minus(inputWei);
        } else {
          activeValue = activeValue.minus((inputWei.minus(pendingValue)))
          pendingValue = new BigNumber(0);
          activeValue = activeValue.isGreaterThanOrEqualTo(new BigNumber(0)) ? activeValue : new BigNumber(0);
        }
      }
      if (!activeValue.isZero()) {
        arr.push({
          name: item.name,
          value: weiBigNumberToNormalValueFixed(activeValue),
        });
      }
      if (!pendingValue.isZero()) {
        arr.push({
          name: `${item.name} (pending)`,
          value: weiBigNumberToNormalValueFixed(pendingValue),
        });
      }
    });
    if (!match) {
      // add new record
      const item = find(this.validators, (a) => addressIsEqual(a.address, this.address));
      if (this.tabIndex === 0 && inputValue > 0) {
        arr.push({
          name: `${item?.name} (pending)`,
          value: inputValue,
        });
      }
    }
    return arr;
  }

  // private get lockedResult() {
  //   if (this.tabIndex === 0) {
  //     return parseFloat(
  //       weiBigNumberToNormalValueFixed(this.userSummary.userBalance.totalLocked),
  //     ) - this.inputNumber;
  //   }
  //   return parseFloat(
  //     weiBigNumberToNormalValueFixed(this.userSummary.userBalance.totalLocked),
  //   ) + this.inputNumber;
  // }

  public tapMax(): void {
    switch (this.tabIndex) {
      case 0:
        this.inputValue = weiBigNumberToNormalValue(this.userSummary?.userBalance.nonVoting);
        break;
      case 1:
        if (this.userSummary) {
          this.inputValue = weiBigNumberToNormalValue(this.votesForValidator.all);
        }
        break;
      default:
        break;
    }
  }

  public onContinue(): void {
    if (this.tabIndex === 0 && this.votedList.length >= 10) {
      AppModule.TOAST(
        {
          type: "error",
          message: "You can only vote for 10 validators",
        }
      )
      return;
    }
    const extraData: any = {};
    this.votedListResult.forEach((item) => {
      extraData[item.name] = item.value;
    });
    extraData.Locked = weiBigNumberToNormalValueFixed(this.userSummary.userBalance.totalLocked);
    switch (this.tabIndex) {
      case 0:
        this.modalData = {
          type: "Vote",
          value: this.inputValue,
          fee: "0",
          extraData,
        };
        break;
      case 1:
        this.modalData = {
          type: "Revoke",
          value: this.inputValue,
          fee: "0",
          extraData,
        };
        break;
      default:
        break;
    }
    this.reviewLock = true;
  }

  private async modalConfirm() {
    this.reviewLock = false;
    if (UserModule.contractKit) {
      AppModule.START_LOADING("Sending");
      const value = normalValueToWeiBignumber(this.inputValue);
      try {
        switch (this.tabIndex) {
          case 0:
            await UserModule.contractKit.electionContract.voteForValidator(
              this.address,
              value,
            );
            break;
          case 1:
            await UserModule.contractKit.electionContract.revoke(
              this.address,
              UserModule.address,
              normalValueToWeiBignumber(this.inputValue)
            );
            break;
          default:
            break;
        }
        AppModule.END_LOADING();
        await this.loadData();
      } catch (e) {
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: "error",
          message: e.message,
        });
      }
    }
  }

  private async loadValidators() {
    if (UserModule.contractKit) {
      // const res = await UserModule.contractKit.getEligibleValidators();
      // this.validators = this.allValidators;
      const address = find(this.validators, (a) => addressIsEqual(a.address, this.address))?.address;
      if (address) {
        this.selectedValidator = address;
      }
    }
  }

  private async loadData() {
    this.loading = true;
    this.inputValue = "";
    if (UserModule.contractKit) {
      [this.votedList, this.votesForValidator, this.userSummary] = await Promise.all([
        UserModule.contractKit.getValidatorsAndVotesVotedByAccount(UserModule.address),
        UserModule.contractKit.electionContract.getAllVotesForValidatorByAccount(
          this.address,
          UserModule.address
        ),
        UserModule.contractKit.getAccountSummary(UserModule.address),
        UserModule.contractKit.accountsContract.getName(this.address),
      ]);
      const arr1 = [];
      this.votedList.forEach((item) => {
        if (!item.active.isZero()) {
          arr1.push({
            name: item.name,
            value: parseFloat(weiBigNumberToNormalValueFixed(item.active)),
            address: item.address,
          });
        }
        if (!item.pending.isZero()) {
          arr1.push({
            name: `${item.name} (pending)`,
            value: parseFloat(weiBigNumberToNormalValueFixed(item.pending)),
            address: item.address,
          });
        }
      });
      arr1.push({
        name: "Unused Locked",
        value: parseFloat(weiBigNumberToNormalValueFixed(this.userSummary.userBalance.nonVoting)),
        address: ""
      });
      this.summaryTotal = parseFloat(
        weiBigNumberToNormalValueFixed(this.userSummary.userBalance.totalLocked)
      );
      this.summaryList = arr1;
      this.loading = false;
    }
  }

  @Watch("tabIndex")
  private tabChange() {
    this.inputValue = "";
  }

  @Watch("selectedValidator")
  private validatorChange(value: string) {
    if (value !== this.address) {
      this.$router.push(`/addVote/${value}`);
    }
  }

  @Watch("address")
  private addressChange() {
    this.$nextTick(() => {
      this.loadData();
      this.loadValidators();
    });
  }

  @Watch("allValidators")
  private validatorsChange(value: IValidator[]) {
    this.validators = [
      ...value.filter((item) => item.status === 1),
      ...value.filter((item) => item.status === 2),
    ].map((item) => {
      return {
        address: item.validator,
        votes: new BigNumber(item.totalVoteNum),
        name: item.name + (item.status === 2 ? " (Deregistered)" : ""),
        percent: new BigNumber(item.commission)
      }
    });
    this.$nextTick(() => {
      this.loadValidators();
    })
  }

  private created() {
    this.loadData();
    this.loadValidators();
  }
}
